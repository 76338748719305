<template>
  <div data-app>
    <div class="container">
      <!-- 3°third ROW -->
      <!--
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h3>OrderList</h3>
            </div>
            <div class="card-body">
              <draggable
                class="list-group"
                :list="list1"
                :options="{group: 'people'}"
                @change="log"
              >
                <div
                  class="list-group-item"
                  v-for="(element, index) in list1"
                  :key="element.name"
                >{{ element.name }} {{ index }}</div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h3>Market Place</h3>
            </div>
            <div class="card-body">
              <draggable
                class="list-group"
                :list="list2"
                :options="{group: 'people'}"
                @change="log"
              >
                <div
                  class="list-group-item"
                  v-for="(element, index) in list2"
                  :key="element.name"
                >{{ element.name }} {{ index }}</div>
              </draggable>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row">
        <!--<div class="col-2">
          <button class="btn btn-secondary button" @click="sort">To original order</button>
        </div>-->

        <!--<div class="col-md-6">
          <div class="card">
            <div class="card-header bg-success text-white">
              
            <v-tooltip top>
              <template v-slot:activator="{ on}">
                <span v-on="on" class="badge badge-primary badge-pill float-right sdw">
                  <i class="fa fa-paste text-white"></i>
                </span>
              </template>
              <span>Ordenes</span>
            </v-tooltip>
            <h3>Ordenes</h3>
            </div>
            <div class="card-body">
              <div class="p-2 alert alert-secondary">
                <draggable
                  class="list-group fullwidth"
                  tag="ul"
                  v-model="list"
                  v-bind="dragOptions"
                  :options="{group: 'card'}"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group type="transition" name="flip-list">
                    <li
                      class="list-group-item"
                      v-for="element in list"
                      :key="element.id"
                    >
                      <i
                        :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
              "
                        @click="element.fixed = !element.fixed"
                        aria-hidden="true"
                      ></i>
                      
                        <h6 class="my-0">{{ element.name }}</h6>
                        <small class="text-muted">Breve Descrición de los Productos y Packs.</small>
                      
                      
                        <span class="badge badge-secondary badge-pill float-right">
                          {{element.id}}

                        </span>

                    </li>-->
                    <!--<li class="list-group-item" v-for="element in list" :key="element.id">
                      <i
                        :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
              "
                        @click="element.fixed = !element.fixed"
                        aria-hidden="true"
                      ></i>
                       
                    </li>-->
                  <!--</transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>-->
        <!--<div class="col-6">
          <div class="card">
            <div class="card-header">
              <v-tooltip top>
              <template v-slot:activator="{ on}">
                <span v-on="on" class="badge badge-warning badge-pill float-right sdw">
                  <i class="fab fa-sketch text-dark"></i>
                </span>
              </template>
              <span>Market Place</span>
            </v-tooltip>
              <h3>Market Place</h3>
            </div>
            <div class="card-body">
              <div class="p-2 alert alert-warning">
                <draggable
                  class="list-group fullwidth"
                  tag="ul"
                  v-model="lust"
                  v-bind="dragOptions2"
                  :options="{group: 'card'}"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group type="transition" name="flip-list-2">
                    <li class="list-group-item" v-for="element in lust" :key="element.id">
                      <i
                        :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
              "
                        @click="element.fixed = !element.fixed"
                        aria-hidden="true"
                      ></i>
                      
                        <h6 class="my-0">{{ element.name }}</h6>
                        <small class="text-muted">Breve Descrición del Producto En el Market Place.</small>
                      
                      
                        <span class="badge badge-secondary badge-pill float-right">
                          {{element.id}}
                        </span>
                    </li>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="row">
        <!--<div class="col-md-12 text-center">
          <vue-list-picker :left-items="leftItems" :right-items="rightItems" />
        </div>-->
      </div>
      <!-- 2°first ROW 
      <div class="row">
        <div class="col-md-12">-->
          <!-- 2°first Card 
          <div class="card">
            <div class="col form-inline">
              <b-form-input v-model="newTask" placeholder="Enter Task" @keyup.enter="add"></b-form-input>
              <b-button class="ml-2" variant="primary" @click="add">Add</b-button>
            </div>
          </div>
        </div>
      </div>-->
      <!-- 2°first ROW 
      <div class="row">
        <div class="col-md-3">-->
          <!-- 3°first Card 
          <div class="card">
            <div class="card-header">
              <h3>BackLog</h3>
            </div>
            <div class="card-body">
              <div class="p-2 alert alert-secondary">
                <draggable
                  class="list-group"
                  @add="add"
                  :list="arrBacklog"
                  :options="{group: 'task'}"
                >
                  <div
                    class="list-group-item"
                    v-for="element in arrBacklog"
                    :key="element.name"
                  >{{element.name}}</div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">-->
          <!-- 3°first Card 
          <div class="card">
            <div class="card-body">
              <div class="p-2 alert alert-primary">
                <h3>arrInProgress</h3>
                <draggable class="list-group" :list="arrInProgress" :options="{group: 'task'}">
                  <div
                    class="list-group-item"
                    v-for="element in arrInProgress"
                    :key="element.name"
                  >{{element.name}}</div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">-->
          <!-- 3°first Card 
          <div class="card">
            <div class="card-header">
              <h3>inTested</h3>
            </div>
            <div class="card-body">
              <div class="p-2 alert alert-warning">
                <draggable class="list-group" :list="arrTested" :options="{group: 'task'}">
                  <div
                    class="list-group-item"
                    v-for="element in arrTested"
                    :key="element.name"
                  >{{element.name}}</div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">-->
          <!-- 3°first Card 
          <div class="card">
            <div class="card-header">
              <h3>Done</h3>
            </div>
            <div class="card-body">
              <div class="p-2 alert alert-success">
                <draggable class="list-group" :list="arrBacklog" :options="{group: 'task'}">
                  <div
                    class="list-group-item"
                    v-for="element in arrDone"
                    :key="element.name"
                  >{{element.name}}</div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <!-- 1°first ROW 
      <div class="row">
        <div class="col-md-12">-->
          <!-- 1°first Card 
        <div class="card">
          <div class="card-header bg-secondary">
            Movimientos
            <v-tooltip top>
              <template v-slot:activator="{ on}">
                <span v-on="on" class="badge badge-success badge-pill float-right sdw">
                  <i class="fas fa-exchange-alt text-white"></i>
                </span>
              </template>
              <span>Operaciones de traslado de productos a bodegas</span>
            </v-tooltip>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :items="movements"
              :search="search"
              sort-by="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-text-field
                    class="text-xs-center"
                    v-model="search"
                    append-icon="search"
                    label="Búsqueda"
                  ></v-text-field>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">-->
          <!-- 2°second Card 
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
          <v-container>-->
          <!-- 2°second ROW 
                          <v-row>
                            <v-col cols="12" sm="6" md="2" v-if="editedItem.id">
                              <v-text-field
                                v-model="editedItem.id"
                                name="id"
                                label="ID"
                                readonly="readonly"
                                prepend-icon="vpn_key"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="editedItem.name"
                                label="Movimiento"
                                prepend-icon="add_location"
                              ></v-text-field>+
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#FF6347" text @click="close">Cancelar</v-btn>
                        <v-btn color="#00FF00" text @click="save">Guardar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <router-link :to="`/movement/movement-detail/${item.id}`">
                      <v-icon v-on="on" small class="mr-2">mdi-eye</v-icon>
                    </router-link>
                  </template>
                  <span>Ver Movimientos</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </v-data-table>
          </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<style scoped>
.card:hover,
.sdw {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  color: black;
}
.fullwidth {
  width: 100%;
}
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import draggable from "vuedraggable";
//import { VueListPicker } from "vue-list-picker";

export default {
  components: {
    //draggable,
    //VueListPicker,
  },
  data: () => ({
    name: "transition-example",
    display: "Transition",
    order: 6,
    leftItems: [
      {
        key: 1,
        content: "TAGATESSE STIKS CAJA 50 SOBRES",
      },
      {
        key: 2,
        content: "Saco Alusweet 10 kilos",
      },
    ],
    rightItems: [
      {
        key: 3,
        content: "Alusweet 250 g",
      },
      {
        key: 4,
        content: "TagaSweet Polvo 500 g",
      },
    ],
    list: [
      { name: "TAGATESSE STIKS CAJA 50 SOBRES", id: 1 },
      { name: "Saco Alusweet 10 kilos", id: 2 },
      { name: "Alusweet 250 g", id: 3 },
      { name: "TagaSweet Polvo 500 g", id: 4 },
    ],
    lust: [
      { name: "Tagatesse Salsas Ketchup 390 g", id: 1 },
      { name: "TagaSweet Líquido 180 Ml", id: 2 },
      { name: "TAGATESSE RECARGA DISP 500 TAB", id: 3 },
      { name: "TAGATESSE STIKS CAJA 50 SOBRES", id: 4 },
    ],
    list1: [
      { name: "John", id: 1 },
      { name: "Joao", id: 2 },
      { name: "Jean", id: 3 },
      { name: "Gerard", id: 4 },
    ],
    list2: [
      { name: "Juan", id: 5 },
      { name: "Edgard", id: 6 },
      { name: "Johnson", id: 7 },
    ],
    newTask: "",
    arrBacklog: [
      { name: "Code Sing Up" },
      { name: "Test Dashboard" },
      { name: "Style Registration" },
      { name: "Help With Design" },
    ],
    arrInProgress: [],
    arrTested: [],
    arrDone: [],
    dialog: false,
    url: "",
    boolean: false,
    search: "",
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Movimientos", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    movements: [],
    defaultRequiredRules: [(v) => !!v || "Este campo es obligatorio"],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Movimientos", route: "/movement" },
    ]);
  },

  created() {
    //this.fetchMovementsByStoreId();

    this.initialize();
  },
  methods: {
    initialize() {
      this.movements = [
        { id: 1, name: "Movement 1" },
        { id: 2, name: "Movement 2" },
        { id: 3, name: "Movement 3" },
      ];
    },
    add: function () {
      this.list.push({ name: "Juan" });
    },
    replace: function () {
      this.list = [{ name: "Edgard" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned",
      };
    },
    log: function (evt) {
      window.console.log(evt);
    },
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    /*add(evt) {
      console.log(evt);
      if (this.newTask) {
        this.arrBacklog.push({ name: this.newTask });
        this.newTask = "";
      }
    },*/
    fetchMovementsByStoreId() {
      //GET MOVEMENTS BY STORE ID
      var vm = this;
      this.axios({
        url: "inventory/movements",
        method: "GET",
      })
        .then((response) => {
          //vm.reason = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    dragOptions2() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>